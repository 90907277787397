import React from "react";

const divStyle = {
  color: "black",
};

function NewsBlog() {
  return (
    <div style={divStyle}>
      <h2>Blog de Novedades</h2>
      <main>
        <p>Proximamente...</p>
      </main>
    </div>
  );
}

export default NewsBlog;
