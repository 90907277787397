import React from "react";

const divStyle = {
  color: "black",
};

function Tutorials() {
  return (
    <div style={divStyle}>
      <h2>Tutoriales</h2>
      <main>
        <p>Próximamente...</p>
      </main>
    </div>
  );
}

export default Tutorials;
