import React from "react";

const divStyle = {
  color: "black",
};

function ContactUs() {
  return (
    <div style={divStyle}>
      <h2>Información de contacto</h2>
      <main>
        <p>Próximamente...</p>
      </main>
    </div>
  );
}

export default ContactUs;
